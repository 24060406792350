import {
  GET_WISHLIST,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAILED,
} from '../actions/wishlist';

const initialState = {
  items: [],
  request: false,
  requestFailed: false,
};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WISHLIST: {
      return {
        ...state,
        request: true,
        requestFailed: false,
      };
    }
    case GET_WISHLIST_SUCCESS: {
      return {
        items: action.data,
        request: false,
        requestFailed: false,
      };
    }
    case GET_WISHLIST_FAILED: {
      return {
        ...state,
        request: false,
        requestFailed: true,
      };
    }
    default:
      return state;
  }
};

export default wishlistReducer;
