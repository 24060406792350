export const GET_WISHLIST = 'GET_WISHLIST_REQUEST';
export const GET_WISHLIST_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_WISHLIST_FAILED = 'GET_INGREDIENTS_FAILED';

export const getWishlist = () => (dispatch) => {
  dispatch({ type: GET_WISHLIST });

  setTimeout(() => {
    dispatch({ type: GET_WISHLIST_SUCCESS, data: ['Make app'] });
  }, 1000);
};
