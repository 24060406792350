function SiteFooter() {
  return (
    <footer className="site-footer uk-section uk-padding-remove-top uk-section-muted uk-flex-column uk-flex uk-flex-center uk-flex-middle">
      <div className="uk-container uk-container-small uk-text-center uk-text-small uk-margin-medium-bottom uk-text-secondary">
        <p className="uk-margin-small">ИНН 7709474010</p>
        <p className="uk-margin-small">КПП 773001001</p>
        <p className="uk-margin-small">ОГРН 1147799016090</p>
        {/* <p className="uk-margin-small">
          121096, город Москва, Кастанаевская ул., д. 18, кв. 12
        </p> */}
      </div>
      <ul className="uk-iconnav">
        <li>
          <a
            href="https://vk.com/igorbutmanfond"
            target="_blank"
            rel="noreferrer noopener"
            className="uk-icon-button"
          >
            <svg
              fill="#1d1d1d"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              width="800px"
              height="800px"
              viewBox="0 0 97.75 97.75"
            >
              <g>
                <path
                  d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z
		 M73.667,54.161c2.278,2.225,4.688,4.319,6.733,6.774c0.906,1.086,1.76,2.209,2.41,3.472c0.928,1.801,0.09,3.776-1.522,3.883
		l-10.013-0.002c-2.586,0.214-4.644-0.829-6.379-2.597c-1.385-1.409-2.67-2.914-4.004-4.371c-0.545-0.598-1.119-1.161-1.803-1.604
		c-1.365-0.888-2.551-0.616-3.333,0.81c-0.797,1.451-0.979,3.059-1.055,4.674c-0.109,2.361-0.821,2.978-3.19,3.089
		c-5.062,0.237-9.865-0.531-14.329-3.083c-3.938-2.251-6.986-5.428-9.642-9.025c-5.172-7.012-9.133-14.708-12.692-22.625
		c-0.801-1.783-0.215-2.737,1.752-2.774c3.268-0.063,6.536-0.055,9.804-0.003c1.33,0.021,2.21,0.782,2.721,2.037
		c1.766,4.345,3.931,8.479,6.644,12.313c0.723,1.021,1.461,2.039,2.512,2.76c1.16,0.796,2.044,0.533,2.591-0.762
		c0.35-0.823,0.501-1.703,0.577-2.585c0.26-3.021,0.291-6.041-0.159-9.05c-0.28-1.883-1.339-3.099-3.216-3.455
		c-0.956-0.181-0.816-0.535-0.351-1.081c0.807-0.944,1.563-1.528,3.074-1.528l11.313-0.002c1.783,0.35,2.183,1.15,2.425,2.946
		l0.01,12.572c-0.021,0.695,0.349,2.755,1.597,3.21c1,0.33,1.66-0.472,2.258-1.105c2.713-2.879,4.646-6.277,6.377-9.794
		c0.764-1.551,1.423-3.156,2.063-4.764c0.476-1.189,1.216-1.774,2.558-1.754l10.894,0.013c0.321,0,0.647,0.003,0.965,0.058
		c1.836,0.314,2.339,1.104,1.771,2.895c-0.894,2.814-2.631,5.158-4.329,7.508c-1.82,2.516-3.761,4.944-5.563,7.471
		C71.48,50.992,71.611,52.155,73.667,54.161z"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://t.me/jazz100ru"
            target="_blank"
            rel="noreferrer noopener"
            className="uk-icon-button"
          >
            <svg
              fill="#1d1d1d"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-6.627-5.373-12-12-12zm5.894 8.221-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.658-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z" />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://butmanfoundation.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="uk-icon-button"
          >
            <svg
              fill="#1d1d1d"
              height="800px"
              width="800px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-143 145 512 512"
            >
              <g>
                <path d="M106.9,365.1c-18.9-33.6-39.1-61-40.6-63C35.8,316.5,13.1,344.6,6,378.4C8.9,378.5,54.5,379.1,106.9,365.1z" />
                <path
                  d="M120.6,401.8c1.4-0.5,2.9-0.9,4.3-1.3c-2.7-6.2-5.7-12.5-8.9-18.6C59.7,398.7,5.7,397.5,3.8,397.5c0,1.1-0.1,2.3-0.1,3.4
		c0,28.1,10.6,53.7,28,73.1v-0.1C31.7,473.9,61.6,420.9,120.6,401.8z"
                />
                <path d="M44.9,486.5c-0.5-0.4-1-0.8-1.5-1.2C44,485.7,44.5,486.2,44.9,486.5z" />
                <path d="M86.8,294.8c-0.1,0-0.2,0.1-0.4,0.1C86.7,294.9,86.8,294.9,86.8,294.8C86.8,294.9,86.8,294.9,86.8,294.8z" />
                <path
                  d="M185.2,319c-19.3-17-44.5-27.3-72.2-27.3c-8.9,0-17.5,1.1-25.8,3.1c1.6,2.2,22.2,29.4,40.9,63.8
		C169.3,343.1,184.9,319.4,185.2,319z"
                />
                <path
                  d="M131.6,418.9c-64.8,22.6-85.8,68.3-85.8,68.3s-0.1-0.1-0.2-0.2c18.6,14.6,42,23.3,67.4,23.3c15.1,0,29.6-3.1,42.7-8.7
		c-1.6-9.6-8-43-23.3-83C132.1,418.7,131.8,418.8,131.6,418.9z"
                />
                <path
                  d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M238.4,426.8
		c-1.7,8.2-4.2,16.3-7.5,24c-3.2,7.6-7.2,14.9-11.8,21.8c-4.6,6.8-9.8,13.1-15.6,18.9c-5.8,5.8-12.2,11-18.9,15.6
		c-6.8,4.6-14.2,8.6-21.7,11.8c-7.7,3.3-15.8,5.8-24.1,7.5c-8.4,1.7-17.1,2.6-25.8,2.6s-17.4-0.9-25.8-2.6c-8.2-1.7-16.3-4.2-24-7.5
		c-7.6-3.2-14.9-7.2-21.7-11.8s-13.1-9.8-18.9-15.6c-5.8-5.8-11-12.2-15.6-18.9c-4.6-6.8-8.6-14.2-11.8-21.8
		c-3.3-7.7-5.8-15.8-7.5-24c-1.7-8.4-2.6-17.1-2.6-25.8c0-8.7,0.9-17.4,2.6-25.8c1.7-8.2,4.2-16.3,7.5-24.1
		c3.2-7.6,7.2-14.9,11.8-21.7c4.6-6.8,9.8-13.1,15.6-18.9s12.2-11.1,18.9-15.6c6.8-4.6,14.2-8.6,21.7-11.8c7.7-3.3,15.8-5.8,24-7.5
		c8.4-1.7,17.1-2.6,25.8-2.6s17.3,0.9,25.8,2.6c8.2,1.7,16.3,4.2,24.1,7.5c7.6,3.2,14.9,7.2,21.7,11.8c6.8,4.6,13.1,9.8,18.9,15.6
		c5.8,5.8,11,12.2,15.6,18.9c4.6,6.8,8.6,14.1,11.8,21.7c3.3,7.7,5.8,15.8,7.5,24.1c1.7,8.4,2.6,17.1,2.6,25.8
		S240.1,418.4,238.4,426.8z"
                />
                <path
                  d="M136.5,374.8c2.5,5.2,5,10.5,7.3,15.9c0.8,1.9,1.6,3.8,2.4,5.6c37.6-4.7,74.6,3.2,76.1,3.6c-0.3-25.9-9.5-49.7-24.9-68.3
		C197.2,331.9,179.7,357.2,136.5,374.8z"
                />
                <path d="M152.8,413.6c14.3,39.4,20.2,71.5,21.3,78.1c24.5-16.6,42-42.8,46.9-73.3C218.7,417.6,188.1,407.9,152.8,413.6z" />
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default SiteFooter;
