const donate = (evt) => {
  evt.preventDefault();
  window.ipayCheckout({
    currency: 'RUB',
    order_number: '',
    description:
      'Пожертвование в фонд поддержки и развития музыкального искусства Игоря Бутмана',
  });
};

const getWord = (people) => {
  let word = '';

  switch (people % 100) {
    case 11:
      word = 'человек';
      break;
    case 12:
      word = 'человек';
      break;
    case 13:
      word = 'человек';
      break;
    case 14:
      word = 'человек';
      break;
    case 15:
      word = 'человек';
      break;
    case 16:
      word = 'человек';
      break;
    case 17:
      word = 'человек';
      break;
    case 18:
      word = 'человек';
      break;
    case 19:
      word = 'человек';
      break;
    default:
      word = '';
  }

  switch (people % 10) {
    case 0:
      word = 'человек';
      break;
    case 1:
      word = word ? word : 'человек';
      break;
    case 2:
      word = word ? word : 'человека';
      break;
    case 3:
      word = word ? word : 'человека';
      break;
    case 4:
      word = word ? word : 'человека';
      break;
    case 5:
      word = word ? word : 'человек';
      break;
    case 6:
      word = word ? word : 'человек';
      break;
    case 7:
      word = word ? word : 'человек';
      break;
    case 8:
      word = word ? word : 'человек';
      break;
    case 9:
      word = word ? word : 'человек';
      break;
    default:
      word = word ? word : 'человек';
  }

  return word;
};

export { donate, getWord };
