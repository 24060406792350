import { configureStore } from '@reduxjs/toolkit';

import wishlistReducer from './services/reducers/wishlist';

const store = configureStore({
  reducer: {
    wishlist: wishlistReducer,
  },
});

export default store;
