import bannerFund from '../../assets/images/fund-1.jpeg';

function BannerFundSection() {
  return (
    <section
      id="fund"
      className="uk-section uk-section-xlarge uk-cover-container"
    >
      <img loading="async" src={bannerFund} alt="" data-uk-cover />
      <div
        className="uk-position-cover"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.55)' }}
      ></div>
      <div className="uk-container uk-position-relative uk-position-z-index">
        <p className="uk-text-large uk-light" style={{ color: '#fff' }}>
          Деятельность нашего фонда многогранна - мы поддерживаем музыкальные
          школы и музеи, помогаем не только в обучении молодых талантов, но и в
          приобретении музыкальных инструментов. Мы издаем музыкальную
          литературу и проводим масштабные фестивали по всей стране! Ежедневно
          мы делаем всё возможное и даже чуточку больше для развития культуры и
          прививая любовь к музыке. Прямо сейчас, пожертвовав любую сумму, Вы
          уже поможете осуществить чью-то мечту!
        </p>
        <div
          className="uk-flex-middle uk-grid-small uk-margin-medium-top uk-child-width-auto uk-grid uk-position-relative"
          data-uk-grid
        >
          <div>
            <a
              className="button button_main uk-button uk-button-secondary uk-button-large uk-border-rounded"
              href="#about"
              data-uk-scroll
            >
              О Фонде
            </a>
          </div>

          <div className="el-item">
            <a
              href="https://securecardpayment.ru/sc/nHGGMQoxiFapfPmY"
              className="button button_accent uk-button uk-button-primary uk-button-large uk-border-rounded"
              target="_blank"
              rel="noreferrer"
            >
              Поддержать фонд
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerFundSection;
